.errorPage_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: opacity 1s;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 950px) {
  .errorPage_container {
    margin-top: 60px;
  }
}
.errorPage_container_title {
  font-size: 3rem;
  color: black;
  font-weight: bold;
  line-height: 1.56;
  margin: 0;
}
.errorPage_container_text {
  font-size: 2rem;
  color: black;
  line-height: 1.56;
  margin: 0;
}
