.buttonButtonComponent {
  width: 100%;
}

.buttonDisabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 10px 25px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
